exports.languages = {
  en: {
    isDefault: true,
    path: `en`,
    locale: `en`,
    label: 'English',
    labelShort: 'EN',
    siteLanguage: `en`,
    ogLanguage: `en_US`,
  },
  it: {
    path: `it`,
    locale: `it`,
    label: 'Italiano',
    labelShort: 'IT',
    siteLanguage: `it`,
    ogLanguage: `it`,
  },
  de: {
    path: `de`,
    locale: `de`,
    label: 'Deutsch',
    labelShort: 'DE',
    siteLanguage: `de`,
    ogLanguage: `de`,
  },
  zh_CN: {
    path: `zh`,
    locale: `zh_CN`,
    label: '简体中文',
    labelShort: '中文',
    siteLanguage: `zh`,
    ogLanguage: `zh_CN`,
  },
};

exports.languagesCodes = Object.keys(exports.languages);
exports.defaultLanguage = Object.keys(exports.languages).find(
  langCode => exports.languages[langCode].isDefault
);
exports.namespaces = [
  'Global',
  'Header',
  'TravelersPage',
  'TravelersPricing',
  'BusinessPage',
  'PressQuotes',
  'SavingsCalculator',
  'Footer',
  'About',
  'DutyFreeDistrictPage',
];
